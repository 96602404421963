import { Link } from "gatsby"
import React from "react"
import GetGGFXImage from "../common/site/get-ggfx-image"

import "./AreaguideLanding.scss"

const AreaguideLandingCard = ({ areaguide }) => {
  if (!areaguide) return null

  let processedImages = JSON.stringify({})
  if (areaguide.imagetransforms) {
    processedImages = areaguide.imagetransforms.Tile_Image_Transforms
  }

  return (
    <div className="areaguide-card">
      <div className="img-zoom card-img">
        <Link to={areaguide.URL + "/ "}>
          <GetGGFXImage
            imagename={"areaguides.Tile_Image.tileimg"}
            imagesource={areaguide.Tile_Image}
            fallbackalt={areaguide.Areaguide_Title}
            imagetransformresult={processedImages}
            id={areaguide.id}
          />
        </Link>
        <div className="card-bottom">
          <Link to={areaguide.URL + "/ "} className="md-area-name">
            <p className="area-name">{areaguide.Areaguide_Title}</p>
          </Link>
          <Link to={areaguide.URL + "/ "} className="btn-link">
            <i className="icon icon-track-slider-link-arrow"></i>
          </Link>
        </div>
      </div>
      <Link to={areaguide.URL + "/ "} className="d-block d-md-none">
        <p className="area-name">{areaguide.Areaguide_Title}</p>
      </Link>
    </div>
  )
}

export default AreaguideLandingCard
