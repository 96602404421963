import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
// import SliderImg1 from "./assets/images/slider-img-1.jpg"
// import SliderImg2 from "./assets/images/slider-img-2.jpg"
import parse from "html-react-parser"
import TrackRecordSliderCard from "./TrackRecordSliderCard"
import { addTrackRecordType, alternateMerge } from "../TrackRecordPage/helper"

const TrackRecordSlider = ({ module }) => {
  const records_let = addTrackRecordType("let", module.let_records)
  const records_sold = addTrackRecordType("sold", module.sold_records)

  const track_records = alternateMerge(records_sold, records_let)

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="track-record-slider-wrapper">
      <Container>
        <Row>
          <Col>
            <div className="track-record-sm-text">{module.heading}</div>
            <div className="track-record-heading">
              {module.title && parse(module.title)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {track_records && (
              <Slider className="track-record-slider" {...settings}>
                {track_records.length > 0 &&
                  track_records.map(track_record => (
                    <TrackRecordSliderCard
                      record={track_record.record}
                      type={track_record.type}
                    />
                  ))}
              </Slider>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TrackRecordSlider
