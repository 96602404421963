import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import OurPeopleSliderCard from "./OurPeopleSliderCard"
import { sort } from "../common/site/utils"

const OurPeopleSlider = () => {
  const data = useStaticQuery(graphql`
    query getTeamsSliderData {
      glstrapi {
        teams {
          Name
          URL
          sort_order
          Staff_Image {
            url
            alternativeText
          }
          imagetransforms
          id
        }
      }
    }
  `)

  const teams = data.glstrapi.teams
  const sortedTeams = teams.sort((a, b) => sort(a.sort_order, b.sort_order))

  // Slider settings
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 3,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="our-people-slider-wrapper">
      <Container>
        <Row>
          <Col>
            <div className="our-people-sm-text text-uppercase">Our People</div>
            <h2 className="our-people-heading ">
              Experts in our area. And in our fields.
            </h2>
            <div className="our-people-text d-none d-md-block">
              We are award-winning property professionals, customer service
              specialists, and location experts.{" "}
              <Link to={"/meet-the-team/"}>Meet the team</Link> who will guide
              you home.
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Slider className="our-people-slider" {...settings}>
              {sortedTeams.map(team => (
                <OurPeopleSliderCard team={team} />
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OurPeopleSlider
