import React from "react"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import TrackRecordSection from "./TrackRecordSection"
import { addTrackRecordType, alternateMerge } from "./helper"
import parse from "html-react-parser"
import SiteBreadcrumbs from "../Breadcrumb/site-breadcrumbs"
import { graphql, useStaticQuery } from "gatsby"

const TrackRecordPage = ({ module, title }) => {
  const data = useStaticQuery(graphql`
    query GetTrackRecordData {
      glstrapi {
        trackRecord {
          let_properties {
            ref
            id
            property {
              display_address
              images
              imagetransforms
              slug
              id
            }
          }
          sold_properties {
            ref
            id
            property {
              display_address
              images
              imagetransforms
              slug
              id
            }
          }
        }
      }
    }
  `)

  const records_sold = addTrackRecordType(
    "sold",
    data.glstrapi.trackRecord.sold_properties
  )
  const records_let = addTrackRecordType(
    "let",
    data.glstrapi.trackRecord.let_properties
  )
  // const records_let = addTrackRecordType("let", module.let_records)
  // const records_sold = addTrackRecordType("sold", module.sold_records)

  const track_records = alternateMerge(records_sold, records_let)
  // const track_records = [...records_sold, ...records_let]

  return (
    <>
      <SiteBreadcrumbs style="landing-breadcrumbs" />

      <Container className="contact-form-wrapper">
        <Row>
          <Col lg={12}>
            <div className="contact-title track-record-title">
              {title && parse(title)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs defaultActiveKey="allproperties" className="custom-tabs">
              <Tab eventKey="allproperties" title="All Properties">
                <TrackRecordSection records={track_records} />
              </Tab>
              <Tab eventKey="soldonly" title="Sold">
                <TrackRecordSection records={records_sold} />
              </Tab>
              <Tab eventKey="letonly" title="Let">
                <TrackRecordSection records={records_let} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TrackRecordPage
