import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import TeamLandingCard from "./TeamLandingCard"
import { sort } from "../common/site/utils"

const TeamLanding = () => {
  const data = useStaticQuery(graphql`
    query getTeamListing {
      glstrapi {
        teams {
          Name
          URL
          sort_order
          Staff_Image {
            url
            alternativeText
          }
          imagetransforms
          id
        }
      }
    }
  `)

  const teams = data.glstrapi.teams
  console.log("🚀 ~ file: TeamLanding.js:30 ~ TeamLanding ~ teams:", teams)
  const sortedTeams = teams.sort((a, b) => sort(a.sort_order, b.sort_order))
  console.log(
    "🚀 ~ file: TeamLanding.js:32 ~ TeamLanding ~ sortedTeams:",
    sortedTeams
  )

  return (
    <section className="team-landing-wrapper">
      <Container>
        <Row>
          <Col>
            <div className="our-people-slider">
              <Row>
                {sortedTeams.map(team => (
                  <TeamLandingCard team={team} />
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TeamLanding
