import React from "react"
import { Container } from "react-bootstrap"
import "./BannerLanding.scss"
import parse from "html-react-parser"
import SiteBreadcrumbs from "../Breadcrumb/site-breadcrumbs"
import GetLink from "../functions/GetLink"
const BannerLanding = (props) => {
  return <>
    <SiteBreadcrumbs style="landing-breadcrumbs"/>
    <Container className={`banner-landing-wrapper ${props.layout=="Static" ? 'static-banner' : ''}`}>
      {props.content &&
        <div className="banner-landing-container">
          <div className="banner-landing-content">{parse(props.content)}</div>
          {props.cta  && props.cta.map((item, index) => (
            <div className="banner-btns">
              <GetLink className={`btn btn-banner btn-primary-outline`} label={item.CTA_Label} link={item.CTA_Link}/>
            </div>
            ))
          }
        </div>
      }
    </Container>
    </>  
}

export default BannerLanding
