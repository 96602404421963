import React from 'react'
import { Container, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './PageIntro.scss'
function ClientLogos(props) {
  return (
    <Container className='page-intro text-center'>
        <Col lg={10} className="m-auto">
            {parse(props.content)}
        </Col>
    </Container>
  )
}

export default ClientLogos