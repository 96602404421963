import React from 'react'
import { Form, Button, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2}) => (
	<>
		<div className="contact-terms">By clicking Submit Enquiry, you agree to our <Link to="/terms-and-conditions/">Terms & Conditions</Link> and <Link to="/privacy-policy/">Privacy Policy.</Link></div>
		<Button variant="" type={type} className={`btn btn-primary-outline w-100 ${fieldClass}`}>
			{name}
			<i className="icon-right"></i>
		</Button>
	</>
);

export default ButtonField;