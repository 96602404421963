import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import $ from "jquery";
import {Container} from "react-bootstrap"
import './_sitemap.scss'
const SiteMap = (props) => {
  useEffect(() => {
    $('.navigation li a').click(function () {
      $(window).scrollTop(0);
    })
    $(".navigation .menu-accordian .submenu-list").each(function (i, el) {
      // Remove white space
      var html = $(el).html().replace(/\s+/, "");
      // Check if element is :empty or length of html is zero
      if ($(el).is(":empty") || html.length === 0) {
        $(el).remove();
      } else {
        $(el).parent().addClass('has-submenu');
      }
    });

  }, []);

  const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Burger_Menu
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL            
          }
        }
      }
    }
  `)
  var menu_data = data.glstrapi.allMenus;
  var IfExternal_URL = /^((http|https|ftp):\/\/)/;
  return (
    <React.Fragment>
    <Container>
      <div className="sitemap-links">
          {menu_data.map((mainmenu, index) => {
            return <>
              {mainmenu.Show_In_Burger_Menu ?
                <li className={"card-header card-header-parent"}>
                  {mainmenu.Secondary_URL == null ?
                    <Link to={`/${mainmenu.Primary_URL}/`} className="firstleveltrigger parent-link">{mainmenu.Show_In_Burger_Menu === true && mainmenu.Label}</Link>
                    :
                    <Link to={mainmenu.Secondary_URL} className="firstleveltrigger parent-link">{mainmenu.Show_In_Burger_Menu === true && mainmenu.Label}</Link>
                  }
                  <div className="firstleveltrigger">
                    {menu_data.map((menuitem, key) => {
                      return <>
                        {menuitem.Main_Parent && menuitem.Sub_Parent === null && menuitem.Main_Parent.Label === mainmenu.Label &&
                          <i className="icon-arrow-down"></i>
                        }
                      </>
                    })}
                  </div>
                  <ul className="submenulist">
                    {menu_data.map((submenu) => {
                      return <>
                        {submenu.Main_Parent && submenu.Sub_Parent === null && submenu.Main_Parent.Label === mainmenu.Label && submenu.Secondary_URL == null &&
                          <li className="card-header card-header-child secondlevel-menu-item">
                            <Link to={`/${submenu.Main_Parent.Primary_URL}/${submenu.Primary_URL}/`}>{submenu.Label}</Link>
                          </li>
                        }
                        {submenu.Main_Parent && submenu.Sub_Parent === null && submenu.Main_Parent.Label === mainmenu.Label && submenu.Secondary_URL != null &&
                          <li className="card-header card-header-child secondlevel-menu-item">
                            {IfExternal_URL.test(submenu.Secondary_URL) ?
                              <a target="_blank" href={submenu.Secondary_URL}>{submenu.Label}</a>
                              :
                              <Link to={submenu.Secondary_URL}>{submenu.Label}</Link>
                            }
                          </li>
                        }
                      </>
                    })}
                  </ul>

                </li>
                : ""}
            </>
          })}
      </div>
      </Container>
    </React.Fragment>
  );
};

export default SiteMap;

