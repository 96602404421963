export function alternateMerge(arr1, arr2) {
  if (!arr1) {
    if (!arr2) {
      return null
    } else {
      return arr2
    }
  } else if (!arr2) {
    return arr1
  }

  // if (!arr1 || arr2) return arr2
  // if (arr1 || !arr2) return arr1
  // if (!(arr1 && arr2)) return null

  const n1 = arr1.length
  const n2 = arr2.length

  let i = 0,
    j = 0,
    k = 0

  const mergedArr = new Array(arr1.length + arr2.length)

  // Traverse both array
  while (i < n1 && j < n2) {
    mergedArr[k++] = arr1[i++]
    mergedArr[k++] = arr2[j++]
  }

  // Store remaining elements of first array
  while (i < n1) mergedArr[k++] = arr1[i++]

  // Store remaining elements of second array
  while (j < n2) mergedArr[k++] = arr2[j++]

  return mergedArr
}

export function addTrackRecordType(type, records) {
  if (records.length === 0) return null

  return records.map(record => {
    return { type, record: record.property ? record.property : record }
  })
}

export const getPropertyBaseURI = type =>
  type === "sold" ? "/property-for-sale/" : "/property-to-rent/"
