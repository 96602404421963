import React from "react"
import "./assets/styles/_index.scss"
import { Link } from "gatsby"
import { Col } from "react-bootstrap"
import GetGGFXImage from "../common/site/get-ggfx-image"
const OurPeopleSliderCard = ({ team }) => {
  if (!team) {
    return null
  }
  let processedImages = JSON.stringify({});
  if (team?.imagetransforms?.Staff_Image_Transforms) {
   processedImages = team.imagetransforms.Staff_Image_Transforms;
  }
  return (
    <Col xl={2} lg={3} md={6} xs={6} className="landing-team-wrap">
      <div className="our-people-slide">
        <div className="our-people-img-zoom">
          {team.Staff_Image && (
            <Link to={team.URL+'/ '}>
              <GetGGFXImage imagename={"teams.Staff_Image.tileimg"} imagesource={team.Staff_Image} fallbackalt={team.Name} imagetransformresult={processedImages} id={team.id}/>                  
            </Link>
          )}
          <div className="our-people-slide-display-adderss-wrapper d-flex justify-content-md-between justify-content-end">
            <div className="our-people-slide-display-address d-md-flex align-items-center justify-content-center d-none">
            <Link to={team.URL+'/ '}>
              <span>{team.Name}</span>
            </Link>
            </div>
            <Link to={team.URL+'/ '} className="btn-link">
              <i className="icon icon-track-slider-link-arrow"></i>
            </Link>
          </div>
        </div>
        <div className="d-md-none d-block our-people-slide-display-address-sm">
          <Link to={team.URL+'/ '}>
            <span>{team.Name}</span>
          </Link>
        </div>
      </div>
    </Col>
  )
}

export default OurPeopleSliderCard
