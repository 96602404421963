import { Link } from "gatsby"
import React from "react"
import "./NewsLanding.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"

const NewsLandingCard = ({ news },) => {
  if (!news) return null
  let processedImages = JSON.stringify({});
  if (news?.imagetransforms?.Tile_Image_Transforms) {
   processedImages = news.imagetransforms.Tile_Image_Transforms;
  }
  return (
    <div className="areaguide-card">
      <div className="img-zoom card-img">
        <Link to={news.URL +'/ '}>
          {/* <img
            src={news.Tile_Image?.url}
            alt={`image of ${news.Title}`}
          /> */}
          <GetGGFXImage imagename={"news-events.Tile_Image.tileimg"} imagesource={news.Tile_Image} fallbackalt={news.Title} imagetransformresult={processedImages} id={news.id}/>                  
        </Link>
        <div className="card-bottom">
          {/* <Link to={news.URL} className="md-area-name">
            <p className="area-name">{news.Title}</p>
          </Link> */}
          <Link to={news.URL +'/ '} className="btn-link">
            <i className="icon icon-track-slider-link-arrow"></i>
          </Link>
        </div>
      </div>
      <Link to={news.URL +'/ '}>
        <p className="area-name">{news.Title}</p>
      </Link>
    </div>
  )
}

export default NewsLandingCard
