import React from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./IconBlocks.scss"

const IconBlocks = ({ module }) => {
  return (
    <Container className="icon-block-wrapper">
      <div className="icon-block-container">
        {module.Block_Description && (
          <div className="icon-block-text">
            {parse(module.Block_Description)}
          </div>
        )}
        <div className="icon-blocks">
          {module?.Icon_Listing?.map(block => (
            <div className="icon-block">
              <i className={`icon-${block.Icon_Class}`}></i>
              <p className="title">{block.Icon_Title}</p>
              <div className="description">{parse(block.Icon_Intro)}</div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default IconBlocks
