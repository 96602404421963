import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/global.scss"
import { graphql } from "gatsby"
import CommonTemplate from "./common-template"
import SEO from "../components/Seo/seo"

const DefaultTemplate = props => {
  const GQLPage = props.data.glstrapi?.article
  const GQLModules = props.data.glstrapi?.article?.Modules
  const locationState = props.location.state
  return (
    <>
      <SEO
        title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
        description={GQLPage.Meta_Description}
      />
      <CommonTemplate
        GQLPage={GQLPage}
        GQLModules={GQLModules}
        locationState={locationState}
      />
    </>
  )
}
export default DefaultTemplate
export const pageQuery = graphql`
  query DefaultQuery($articleId: ID!) {
    glstrapi {
      article(id: $articleId, publicationState: LIVE) {
        id
        Title
        Meta_Title
        Meta_Description
        Banner_Content
        Layout
        Select_Popular_Search
        imagetransforms
        Page_Class
        banner_videos {
          url
        }
        Banner_Image {
          url
          alternativeText
        }
        Banner_CTA {
          CTA_Label
          CTA_Link {
            Primary_URL
          }
          CTA_Secondary_Link
        }

        Modules {
          ... on GLSTRAPI_ComponentComponentsTileBlock {
            __typename
            id
            Tile_BG
            Tile_CTA {
              CTA_Label
              CTA_Link {
                Primary_URL
                Secondary_URL
                Show_In_Top_Nav
                Sorting
                Label
                id
              }
              CTA_Secondary_Link
              id
            }
            Tile_Content
            Tile_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 800
                    height: 540
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
              url_sharp_sm: url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    width: 560
                    height: 560
                    quality: 50
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Tile_Image_Position
            Tile_Title
          }

          ... on GLSTRAPI_ComponentComponentsGetInTouch {
            __typename
            id
            Get_In_Touch_BG
            Show_Get_In_Touch
          }

          ... on GLSTRAPI_ComponentComponentsAreaguideTextContent {
            __typename
            id
            Text
          }
          ... on GLSTRAPI_ComponentComponentsImageModule {
            __typename
            id
            Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 920
                    height: 560
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsTrackRecordPageData {
            __typename
            id
            let_records {
              display_address
              images
              imagetransforms
              slug
              id
            }
            sold_records {
              display_address
              images
              imagetransforms
              slug
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentsTrackRecord {
            __typename
            id
            heading
            title
            let_records {
              display_address
              images
              imagetransforms
              slug
              id
            }
            sold_records {
              display_address
              images
              imagetransforms
              slug
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentsHomeIntro {
            __typename
            id
            CTA_Label_1
            CTA_Label_2
            CTA_Link_1 {
              Primary_URL
              Secondary_URL
              Sorting
              Label
              id
            }
            CTA_Link_2 {
              Primary_URL
              Secondary_URL
              Sorting
              Label
              id
            }
            Home_Intro_Text
          }

          ... on GLSTRAPI_ComponentComponentsIconBlocks {
            __typename
            id
            Block_Description
            Icon_Listing {
              Icon_Class
              Icon_Intro
              Icon_Title
              id
            }
          }
          ... on GLSTRAPI_ComponentComponentsStaticPage {
            __typename
            id
            Static_Content
          }
          ... on GLSTRAPI_ComponentComponentsGlobalModules {
            __typename
            id
            Select_Module
          }
          ... on GLSTRAPI_ComponentComponentsPageIntro {
            __typename
            id
            Page_Intro_Text
          }
          ... on GLSTRAPI_ComponentComponentsForms {
            __typename
            id
            Select_Forms
          }
          ... on GLSTRAPI_ComponentComponentsClientMembersModule {
            id
            __typename
            Module_Content
            Logo_List {
              Logo_Link
              Logo_Image {
                url
                alternativeText
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            id
            FAQ_Content
            FAQ_Listing {
              List_Title
              List_Content
            }
          }
        }
      }
    }
  }
`
