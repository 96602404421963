import React from "react"
import { Container } from "react-bootstrap"
import "./TileBlock.scss"
import parse from "html-react-parser"
import GetLink from "../functions/GetLink"
import GetGatsbyImage from "../common/site/get-gatsby-image"
const TileBlock = (props) => {
  var module = props.module
  return (
    <Container className="tile-block-wrapper">
      <div
        className={`tile-block-container ${
          module.Tile_Image_Position === "Right" ? "img-right" : ""
        }`}
      >
        <div className="tile-img">
          <GetGatsbyImage image={module.Tile_Image} fallbackalt={module.Tile_Title} class={props.class}/>
        </div>
        <div className="tile-content">
          <h4 className="tile-heading">{module.Tile_Title}</h4>
          <div className="tile-title-description">
            {parse(module.Tile_Content)}
          </div>
          <div className="tile-cta">
            {module.Tile_CTA && (
              <GetLink
                className="btn btn-white-outline"
                label={module.Tile_CTA.CTA_Label}
                link={module.Tile_CTA.CTA_Link}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default TileBlock
