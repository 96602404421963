import * as React from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Banner from "../components/Banner/Banner"
import TileBlock from "../components/TileBlock/TileBlock"
import OurPeopleSlider from "../components/OurPeopleSlider/OurPeopleSlider"
import HomeIntroModule from "../components/HomeIntroModule/HomeIntroModule"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import AreaguideLanding from "../components/AreaguideLanding/AreaguideLanding"
import TeamLanding from "../components/TeamLanding/TeamLanding"
import NewsLanding from "../components/NewsLanding/NewsLanding"
import IconBlocks from "../components/IconBlocks/IconBlocks"
import SearchBanner from "../components/SearchBanner/SearchBanner"
import TrackRecordSlider from "../components/TrackRecordSlider/TrackRecordSlider"
import FullWidthBlueBanner from "../components/SearchBanner/FullwidthBlueBanner"
import ContactPage from "../components/ContactPage/ContactPage"
import FAQ from "../components/FAQ/FAQ"
import StaticPage from "../components/Static/StaticPage"
import BannerNoBg from "../components/BannerNoBg/BannerNoBg"
import ClientLogos from "../components/ClientLogos/ClientLogos"
import PageIntro from "../components/PageIntro/PageIntro"
import FormTemplate from "./form-template"
import TrackRecordPage from "../components/TrackRecordPage/TrackRecordPage"
import SiteMap from "../components/SiteMap/Sitemap"
import DetailPageGetInTouch from "../components/DetailPageGetInTouch/DetailPageGetInTouch"
import parse from "html-react-parser"
import GetGatsbyImage from "../components/common/site/get-gatsby-image"
import Reviews from "../components/Reviews/Reviews"
import { Container } from "react-bootstrap"

const IndexPage = props => {

  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  
  return (
    <div className={`${GQLPage.Page_Class ? GQLPage.Page_Class : ""}`}>
      <Header />

      {GQLPage.Layout === "Banner_No_Img" ? (
        <BannerNoBg
          content={GQLPage.Banner_Content}
          ctas={GQLPage.Banner_CTA}
        />
      ) : GQLPage.Layout === "Landing" ? (
        <BannerLanding content={GQLPage.Banner_Content} />
      ) : GQLPage.Layout === "Contact_Page" ? (
        <ContactPage
          id={GQLPage.id}
          title={GQLPage.Title}
          content={GQLPage.Banner_Content}
          cta={GQLPage.Banner_CTA}
          image={GQLPage.Banner_Image}
          imagetransforms={GQLPage.imagetransforms}
        />
      ) : GQLPage.Banner_Image && GQLPage.Layout !== "Contact_Page" ? (
        <Banner
          id={GQLPage.id}
          title={GQLPage.Title}
          metattitle={GQLPage.Meta_Title}
          image={GQLPage.Banner_Image}
          imagetransforms={GQLPage.imagetransforms}
          description={GQLPage.Banner_Content}
          cta={GQLPage.Banner_CTA}
          showreview={GQLPage.Show_Reviews}
          videos={GQLPage.banner_videos}
        />
      ) : GQLPage.Layout === "Landing" || GQLPage.Layout === "Static" ? (
        <BannerLanding
          content={GQLPage.Banner_Content}
          layout={GQLPage.Layout}
          cta={GQLPage.Banner_CTA}
        />
      ) : GQLPage.Layout === "Contact_Page" ? (
        <ContactPage
          id={GQLPage.id}
          content={GQLPage.Banner_Content}
          cta={GQLPage.Banner_CTA}
          image={GQLPage.Banner_Image}
          imagetransforms={GQLPage.imagetransforms}
        />
      ) : GQLPage.Layout === "Form" ? (
        <FormTemplate
          id={GQLPage.id}
          content={GQLPage.Banner_Content}
          modules={GQLModules}
          locationState={props.locationState}
        />
      ) : null}

      {GQLModules.map((Module, i) => {
        return (
          <>
            {Module.__typename === "GLSTRAPI_ComponentComponentsHomeIntro" && (
              <HomeIntroModule module={Module} />
            )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsTileBlock" && (
              <TileBlock module={Module} class={GQLPage.Page_Class} />
            )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsIconBlocks" && (
              <IconBlocks module={Module} />
            )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsStaticPage" && (
              <StaticPage content={Module.Static_Content} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsClientMembersModule" && (
              <ClientLogos
                content={Module.Module_Content}
                items={Module.Logo_List}
              />
            )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsImageModule" &&
              Module.Image && (
                <Container className="max-w-920 detail-img-section">
                  <GetGatsbyImage
                    image={Module.Image}
                    fallbackalt={`page detail image - ${i}`}
                  />
                  {/* <img src={content.Image?.url} alt="areaguide detail image" /> */}
                </Container>
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsAreaguideTextContent" &&
              Module.Text && (
                <Container className="max-w-920 detail-text-section">
                  {parse(Module.Text)}
                </Container>
              )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsGetInTouch" && (
              <Container className="max-w-920">
                <DetailPageGetInTouch content={Module} />
              </Container>
            )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsPageIntro" && (
              <PageIntro content={Module.Page_Intro_Text} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Team_Slider_Module" && (
                <OurPeopleSlider />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsTrackRecord" && (
              <TrackRecordSlider module={Module} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsTrackRecordPageData" && (
              <TrackRecordPage module={Module} title={GQLPage.Banner_Content} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Search_Form_Module" && <SearchBanner />}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Lets_Find_Property_Module" && (
                <FullWidthBlueBanner type="letus" />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Are_You_Ready_To_Sell_Module" && (
                <FullWidthBlueBanner type="areyou_ready" />
              )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Get_In_Touch_Module" && (
                <FullWidthBlueBanner type="getintouch" />
              )}

            {Module.__typename === "GLSTRAPI_ComponentComponentsFaq" && (
              <FAQ content={Module.FAQ_Content} items={Module.FAQ_Listing} />
            )}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Areaguide_Listing_Page" && (
                <AreaguideLanding />
              )}
              {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Reviews_Listing_Page" && (
                <Reviews />
              )}
            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Team_Listing_Page" && <TeamLanding />}

            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "News_Listing_Page" && <NewsLanding />}
            {Module.__typename ===
              "GLSTRAPI_ComponentComponentsGlobalModules" &&
              Module.Select_Module === "Sitemap_Page" && <SiteMap />}
          </>
        )
      })}

      <Footer popularsearch={GQLPage.Select_Popular_Search}/>
    </div>
  )
}
export default IndexPage
