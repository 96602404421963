import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import parse from "html-react-parser"
import GetGGFXImage from "../common/site/get-ggfx-image";
import GetLink from '../functions/GetLink'
import './assets/styles/_index.scss';

const ContactPage = (props) => {

    // 
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Banner_Image_Transforms) {
     processedImages = props.imagetransforms.Banner_Image_Transforms;
    }
    //
    return (
        <section className="contact-page-wrapper">
            <Container>
                <Row>
                    <Col lg={5} md={12}>
                        <div className="contact-details">
                            {parse(props.content)}
                            {props.cta  && props.cta.map((item, index) => (
                                <GetLink className={`btn btn-white`} label={item.CTA_Label} link={item.CTA_Link}/>
                                ))
                            }
                        </div>
                    </Col>
                    <Col lg={7} md={12}>
                        <div className="contact-banner">
                            {
                                props.image &&
                                <img src={props?.image?.url} alt={props.title}/>
                                // <GetGGFXImage imagename={"articles.Banner_Image.contactbannerimg"} imagesource={props.image} fallbackalt={props.title} imagetransformresult={processedImages} id={props.id} />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactPage