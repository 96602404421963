import React, { useState } from "react"
import TrackRecordCard from "../TrackRecordCard/TrackRecordCard"
import TrackRecordImg1 from "../../images/trackrecordimg_1.png"
import TrackRecordImg2 from "../../images/trackrecordimg_2.png"
import TrackRecordImg3 from "../../images/trackrecordimg_3.png"
import TrackRecordImg4 from "../../images/trackrecordimg_4.png"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import { Link } from "gatsby"
const ROWS_PER_PAGE = 4

const TrackRecordSection = ({ records }) => {
  const [noOfRecords, setNoOfRecords] = useState(ROWS_PER_PAGE)

  const loadMore = () => {
    setNoOfRecords(noOfRecords + ROWS_PER_PAGE)
  }

  const RecordComponents = []

  for (let i = 0; i < records.length; i++) {
    const changeLayout = i % 4 === 0

    const RecordComponent = (
      <Row className={`${changeLayout ? "track-first" : "track-second"}`}>
        <Col lg={changeLayout ? 7 : 5} className="mb-4 mb-md-5">
          <TrackRecordCard
            recordData={records[i]}
            trackClass={"track-slide-left"}
          />
        </Col>
        {++i < records.length && (
          <Col lg={changeLayout ? 5 : 7} className="mb-4 mb-md-5">
            <TrackRecordCard
              recordData={records[i]}
              trackClass={"track-slide-right"}
            />
          </Col>
        )}
      </Row>
    )
    RecordComponents.push(RecordComponent)
  }

  const recordSlice = RecordComponents.slice(0, noOfRecords)

  return (
    <div className="track-record-section">
      {recordSlice}
      {recordSlice.length !== RecordComponents.length && (
        <Row>
          <Col lg={12}>
            <div className="track-btn d-flex justify-content-center mb-5">
              <button className="btn btn-white-outline" onClick={loadMore}>
                Load More
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default TrackRecordSection
