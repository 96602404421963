import React, {useEffect, useState} from "react"
import GetLink from "../functions/GetLink"
import parse from "html-react-parser"
import "./Banner.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import SiteBreadcrumbs from "../Breadcrumb/site-breadcrumbs"
import { Container } from "react-bootstrap"
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
import googleImg from "../Reviews/google-reviews.svg";

function Banner(props) {
  const {videos} = props
  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms
  }

  const [testimonials, setTestimonials] = useState([])
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }
      })// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC.replace('-dev', '')}/stb-google-reviews/google-review-reviews`
    getitems(url);
  }, [])

  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;

  const [video, setVideo] = useState(null)
  useEffect(() => {
    if (videos && videos.length > 0) {
      setVideo(videos[Math.floor(Math.random() * videos.length)])
    }
  }, [videos])

  return (
    <React.Fragment>
      {props.image && (
        <div className="banner">
          {props.title != "Home" && <SiteBreadcrumbs />}
          {props.title == "Home" ?
          video?.url&&(
          <video
            id="collection-body_id"
            src={video.url}
            controls={false}
            muted={"muted"}
            playsinline={"playsinline"}
            autoPlay={"autoplay"}
            preLoad="auto"
            loop
          />
          )
          :
          <GetGGFXImage
            imagename={"articles.Banner_Image.bannerimg"}
            imagesource={props.image}
            fallbackalt={props.metattitle ? props.metattitle : props.title}
            imagetransformresult={processedImages}
            id={props.id}
          />
          }
          {/* <img src={props.image.url} alt="" /> */}
          {props.title == "Home" ?
          <div className="overlay overlay-video"></div>
          :
          <div className="overlay"></div>
          }
          <Container className="banner-content-wrapper">
            {props.description && (
              <div className="banner-content">{parse(props.description)}</div>
            )}
            {props.cta &&
              props.cta.map((item, index) => (
                <GetLink
                  className={`btn btn-banner`}
                  label={item.CTA_Label}
                  link={item.CTA_Link}
                />
              ))}
            {props.title !== "Reviews" && 
              <div className="tel-banner">
                or call us on{" "}
                <a href="tel:+44 (0)20 7723 9988">+44 (0)20 7723 9988</a>
              </div>
            }
            {props.title === "Reviews" && (
              <div className="review-wrap">
                <div className="img-wrap">
                  <img src={googleImg} alt="google" />
                </div>
                <span className="text-wrap">                       
                  Rated {parseFloat(rating_avg).toFixed(1)}/5 from {testimonial_count} Google Reviews
                </span>
              </div>
            )}
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default Banner
