import { graphql, useStaticQuery } from "gatsby"
import React, {useState, useEffect} from "react"
import { Container } from "react-bootstrap"
import "./NewsLanding.scss"
import NewsLandingCard from "./NewsLandingCard"

const NewsLanding = () => {
  const data = useStaticQuery(graphql`
    query GetNewsLanding {
      glstrapi {
        newsEvents {
          id
          URL
          Title
          News_Published_Date
          Tile_Image {
            url
            alternativeText
          }
          imagetransforms
        }
      }
    }
  `)

  const allNews = data.glstrapi.newsEvents
  const sortedNews = allNews.sort((a, b) => a.News_Published_Date > b.News_Published_Date ? -1 : 1)
  const [news, setNews] = useState(sortedNews);
  const newsPerPage = 8;
  const [newsToShow, setNewsToShow] = useState([]);
  const [next, setNext] = useState(newsPerPage);
  useEffect(() => {
    news && updatenewsToShow(0, newsPerPage);
  }, [news]);
  
  const updatenewsToShow = (start, end) => {
    const slicednews = news.slice(start, end);
    setNewsToShow([...newsToShow, ...slicednews]);
  };

  const handleLoadMore = () => {
    updatenewsToShow(next, next + newsPerPage);
    setNext(next + newsPerPage);
  };


  return (
    <Container className="areaguides-wrapper news-wrapper">
      <div className="areaguides-container">
        {newsToShow.map(news => (
          <NewsLandingCard
            news={news}
            key={news.Title}
          />
        ))}
      </div>
      {newsToShow  && newsToShow.length >= next &&
        <div className="btn-wrap load-more">
          <a onClick={handleLoadMore} className="btn btn-white-outline" >Load More</a>
        </div>
      }

    </Container>
  )
}

export default NewsLanding
