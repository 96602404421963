import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./AreaguideLanding.scss"
import AreaguideLandingCard from "./AreaguideLandingCard"

const AreaguideLanding = () => {
  const data = useStaticQuery(graphql`
    query GetAreaguidesLanding {
      glstrapi {
        areaguides(sort:"sorting:asc") {
          Areaguide_Title
          Tile_Image {
            url
            alternativeText
          }
          URL
          id
          imagetransforms
        }
      }
    }
  `)

  const areaguides = data.glstrapi.areaguides

  return (
    <Container className="areaguides-wrapper">
      <div className="areaguides-container">
        {areaguides.map(areaguide => (
          <AreaguideLandingCard
            areaguide={areaguide}
            key={areaguide.Areaguide_Title}
          />
        ))}
      </div>
    </Container>
  )
}

export default AreaguideLanding
