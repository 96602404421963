import React from "react"
import SliderImg1 from "./assets/images/slider-img-1.jpg"
import { Link } from "gatsby"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { getPropertyBaseURI } from "../TrackRecordPage/helper"
import noImg from "../../images/no-image.png"

const TrackRecordSliderCard = ({ record, type }) => {
  if (!record) return null

  let processedImages = JSON.stringify({})
  if (record?.imagetransforms?.images_Transforms) {
    processedImages = record.imagetransforms.images_Transforms
  }

  const uri = getPropertyBaseURI(type)

  return (
    <div className="track-slide">
      <Link to={`${uri}${record.slug}-${record.id}/`}>
        <div className="track-img-zoom">
          <div className="track-slide-label d-flex align-items-center justify-content-center">
            {type}
          </div>
          {record.images && record.images[0] ? (
            <GetGGFXImage
              imagename={"property.images.detail"}
              imagesource={record.images[0]}
              fallbackalt={record.display_address}
              imagetransformresult={processedImages}
              id={record.id}
            />
          ) : (
            <img src={noImg} className="img-fluid" />
          )}
          <div className="track-slide-display-adderss-wrapper d-flex justify-content-md-between justify-content-end">
            <div className="track-slide-display-address d-md-flex align-items-center justify-content-center d-none">
              <span>{record.display_address}</span>
            </div>
            <Link
              to={`${uri}${record.slug}-${record.id}/`}
              className="track-slide-link d-flex align-items-center justify-content-center"
            >
              <i className="icon icon-track-slider-link-arrow"></i>
            </Link>
          </div>
        </div>
        <div className="d-md-none d-block track-slide-display-address-sm">
          <span>{record.display_address}</span>
        </div>
      </Link>
    </div>
  )
}

export default TrackRecordSliderCard
