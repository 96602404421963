import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import ContactForm from "../components/forms/contact"
import BookingForm from '../components/forms/book-a-viewing';
import SendToFriendForm from '../components/forms/sendfrnd-form';
import ValuationForm from '../components/forms/valuation';
import ContactCard from "../components/ContactCard/ContactCard"
import BookingCard from '../components/ContactCard/BookingCard';
import parse from 'html-react-parser'
import SiteBreadcrumbs from '../components/Breadcrumb/site-breadcrumbs';
import '../components/ContactForm/assets/styles/_index.scss'
import { useLocation } from "@reach/router"
import { parse as Queryparse } from "query-string"
import { GetPropertyBooking } from '../queries/common_use_query';

const FormTemplate = (props) => {
    const GQLModules = props.modules
    var teamemail = props?.locationState?.teamemail   
    var teamsubject = props?.locationState?.emailsubject
    var staffname   = props?.locationState?.staffname
    // Booking Property
    const location = useLocation();    
    const searchParams = Queryparse(location.search) // => {init: "true"}
    const pid = searchParams.pid
    const {loading:prop_loading, error:prop_error, data:prop_data} = GetPropertyBooking(pid);
    var propertyurl= ""
    var backurl = ""
    var propertyslug = ""
    var searchtype = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].search_type:"";
    var displayaddress = prop_data && prop_data.properties.length > 0 ? prop_data.properties[0].display_address:"";
    if(prop_data && prop_data.properties.length > 0) {
        propertyurl =   searchtype=="sales"?"/property-for-sale/":"/property-to-rent/"
        backurl =   searchtype=="sales"?"/property/for-sale/in-london":"/property/to-rent/in-london"
        propertyslug =  prop_data.properties[0].slug+'-'+prop_data.properties[0].id+'/';
    }
    // 
    return (
        <>
            <SiteBreadcrumbs style="landing-breadcrumbs" itemlabel={searchtype === "sales"?'Property for sale' : searchtype === "lettings"?'Property to let' : null } property_url={backurl ? backurl : null} menulabel = {displayaddress ? displayaddress : null}/>
            <Container className="contact-form-wrapper">
                <Row>
                    <Col lg={7}>
                        <div className="contact-title">
                            {staffname ?
                                <>
                                <h1>Contact {staffname}</h1>
                                <p>Please complete the form below and we will respond to your query shortly.</p>
                                </>                                
                                :
                                parse(props.content)                                
                            }
                        </div>
                        {GQLModules.map((Module, i) => {
                            return (
                                <>
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsForms" && Module.Select_Forms === "Contact" && teamemail &&
                                        <ContactForm to_email_id={teamemail} subject={teamsubject}/>
                                                                    
                                    }
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsForms" && Module.Select_Forms === "Contact" && !teamemail &&
                                        <ContactForm />    
                                    }
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsForms" && Module.Select_Forms === "Book_a_Viewing" && prop_data && prop_data.properties.length > 0 &&
                                        <BookingForm property_url={location.origin+propertyurl+propertyslug} property_id={prop_data.properties[0].id} page_url={location.origin+propertyurl+propertyslug} property_title={displayaddress} property_img={prop_data.properties[0].images && prop_data.properties[0].images[0].url} property_address={displayaddress} property_bedrooms={prop_data.properties[0].bedroom} property_bathrooms={prop_data.properties[0].bathroom}/>    
                                    }
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsForms" && Module.Select_Forms === "Send_to_Friend" && prop_data && prop_data.properties.length > 0 &&
                                        <SendToFriendForm property_url={location.origin+propertyurl+propertyslug} property_id={prop_data.properties[0].id} page_url={location.origin+propertyurl+propertyslug} property_title={displayaddress} property_img={prop_data.properties[0].images && prop_data.properties[0].images[0].url} property_address={displayaddress} property_bedrooms={prop_data.properties[0].bedroom} property_bathrooms={prop_data.properties[0].bathroom}/>    
                                    }
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsForms" && Module.Select_Forms === "Valuation" &&
                                        <ValuationForm />    
                                    }
                                </>
                            )
                        })}
                        <div className="contact-terms">* required field</div>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={4} className="mt-lg-0 mt-5">
                        {prop_data && prop_data.properties.length > 0 ?
                            <BookingCard property={prop_data && prop_data.properties[0]} property_url={propertyurl+propertyslug}/>    
                        :
                            <ContactCard />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default FormTemplate