import React from 'react'
import { Container, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './ClientLogos.scss'
function ClientLogos(props) {
  return (
    <Container className='client-logos text-center'>
        <Col lg={10} className="m-auto">
            {parse(props.content)}
            <div className='logos-list'>
                {props.items && props.items.map((logo) => {
                    return<>
                        <div className='logos-wrap'>
                            <img src={logo?.Logo_Image?.url} />
                        </div>
                    </>
                })}
            </div>
        </Col>
    </Container>
  )
}

export default ClientLogos