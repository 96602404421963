import React from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import GetLink from "../functions/GetLink"
import "./HomeIntroModule.scss"

const HomeIntroModule = ({ module }) => {
  return (
    <Container className="home-intro-wrapper">
      <div className="home-intro-container">
        <div className="home-intro-text">{parse(module.Home_Intro_Text)}</div>
        <div className="home-intro-ctas">
          {module.CTA_Link_1 && (
            <GetLink label={module.CTA_Label_1} link={module.CTA_Link_1} />
          )}
          {module.CTA_Link_2 && (
            <GetLink label={module.CTA_Label_2} link={module.CTA_Link_2} />
          )}
        </div>
      </div>
    </Container>
  )
}

export default HomeIntroModule
