import React from 'react'
import { Container, Col } from "react-bootstrap"
import parse from "html-react-parser"
import "./StaticPage.scss"

function StaticPage({content}) {
  return (
    <Container className={`static-page-container`}>  
        <Col lg={10}>
            <div className='static-content'>
                {parse(content)}
            </div>  
        </Col>
    </Container>
  )
}

export default StaticPage