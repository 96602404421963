import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';
import contactImg from './assets/images/img.png';

const BookingCard = (props) => {
    var property = props.property
    if(!property) return null;
    return (
        <section className="contact-card-wrapper booking-card-wrapper">            
            <Row>
                <Link to={props.property_url}>
                <Col lg={12} md={6} className="contact-card-mobile top-card">
                    <img src={property.images && property.images[0].url} className="img-fluid" />
                </Col>
                <Col lg={12} md={6} className="contact-card-mobile bottom-card">
                    <div className="contact-card-box">
                        <div className="contact-card">
                            <h3>{property.display_address} </h3>
                            <h4>{"£"+property.price.toLocaleString()} {property.price_qualifier}</h4>
                            <p>{property.title}</p>
                        </div>
                    </div>
                </Col>
                </Link>
            </Row>
        </section>
    )
}

export default BookingCard