import React from 'react'
import { Accordion, Container, Col } from "react-bootstrap"
import parse from "html-react-parser"
import "./_faq.scss"
function FAQ(props) {
  return (
    <section className='faq-section'>
        <Container className="faq-block-wrapper">
        <Col xl={9} className="justify-content-center text-center m-auto">
            <div className='head-faq-block'>
                {parse(props.content)}
            </div>
            <div className="accordian-section">
                <Accordion flush>
                    {props.items?.map((accordion, i) => (
                    <Accordion.Item eventKey={i} key={"acoordian" + i}>
                        <Accordion.Header>
                        <h4>{accordion.List_Title}</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                        {parse(accordion.List_Content)}
                        </Accordion.Body>
                    </Accordion.Item>
                    ))}
                </Accordion>
                </div>
                </Col>
        </Container>
    </section>
  )
}

export default FAQ